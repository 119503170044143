<template>
  <div>
    <el-image
      style="width: 50px; height: 50px; cursor: pointer"
      :src="url"
      @click="loading = !loading"
    ></el-image>

    <el-dialog
      title="详情"
      :visible.sync="loading"
      width="40%"
      :before-close="handleClose"
    >
      <el-image style="width: 100%; height: 100%" :src="url"></el-image>
      <div style="height: 20px"></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleClose() {
      this.loading = false;
    },
  },
  created() {},
};
</script>

<style lang='less' scoped>
</style>