import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from "element-ui";
import store from '../store'
import { basicRouter } from './DIYRouter'
// console.log(123, basicRouter)

Vue.use(VueRouter)
const routes = basicRouter

// const routes = [
//   // meta: {
//   //   needToken: true, //是否需要登录
//   //   title: '标题', //侧边栏标题
//   //   icon: '标题', //图标
//   //   name: 'xx',//用于权限判断
//   // },
//   {
//     path: '/',
//     redirect: '/login'//重定向
//   },
//   {
//     path: '/login',
//     name: 'login',
//     meta: { needToken: false },
//     component: () => import('../views/login/login1.vue')
//   },
//   {
//     path: '/choose',
//     name: 'choose',
//     meta: { needToken: true },
//     component: () => import('../views/choosepage.vue'),
//   },
//   {
//     path: '/ab',
//     name: 'ab',
//     meta: { needToken: false },
//     component: () => import(/* webpackChunkName: "about" */ '../views/ab.vue')
//   },
//   // {
//   //   path: '/home',
//   //   name: 'home',
//   //   redirect: '/home/homeMain',
//   //   meta: { needToken: true },
//   //   component: () => import('../views/home/home.vue'),
//   //   children: [
//   //     {
//   //       path: 'homeMain',
//   //       name: 'homeMain',
//   //       component: () => import('../views/home/homeMain.vue'),
//   //       meta: {
//   //         needToken: true,
//   //         title: '主页', //侧边栏标题
//   //         icon: 'el-icon-s-home', //图标
//   //       },
//   //     },
//   //     {
//   //       path: 'home1',
//   //       name: 'home1',
//   //       component: () => import('../views/home/home1.vue'),
//   //       meta: {
//   //         needToken: true,
//   //         title: '单页面1', //侧边栏标题
//   //         icon: 'el-icon-s-home', //图标
//   //       },
//   //     }, {
//   //       path: 'home2',
//   //       name: 'home2',
//   //       component: () => import('../views/home/home2.vue'),
//   //       meta: {
//   //         needToken: true,
//   //         title: '单页面2', //侧边栏标题
//   //         icon: 'el-icon-s-home', //图标
//   //       },
//   //     }, {
//   //       path: 'father1',
//   //       name: 'father1',
//   //       component: () => import('../views/home/father1/father1.vue'),
//   //       redirect: 'father1/son1',
//   //       meta: {
//   //         needToken: true,
//   //         title: '无权限模块', //侧边栏标题
//   //         icon: 'el-icon-s-home', //图标
//   //       },
//   //       children: [{
//   //         path: 'son1',
//   //         name: 'father1son1',
//   //         component: () => import('../views/home/father1/son1.vue'),
//   //         meta: {
//   //           needToken: true,
//   //           title: '子页11', //侧边栏标题
//   //           icon: 'el-icon-s-home', //图标
//   //         },
//   //       },
//   //       {
//   //         path: 'son2',
//   //         name: 'father1son2',
//   //         component: () => import('../views/home/father1/son2.vue'),
//   //         meta: {
//   //           needToken: true,
//   //           title: '子页12', //侧边栏标题
//   //           icon: 'el-icon-s-home', //图标
//   //         },
//   //       }]

//   //     }, {
//   //       path: 'father2',
//   //       name: 'father2',
//   //       component: () => import('../views/home/father2/father2.vue'),
//   //       redirect: 'father2/son1',
//   //       meta: {
//   //         needToken: true,
//   //         title: '有权限模块', //侧边栏标题
//   //         icon: 'el-icon-s-home', //图标
//   //       },
//   //       children: [{
//   //         path: 'son1',
//   //         name: 'father2son1',
//   //         component: () => import('../views/home/father2/son1.vue'),
//   //         meta: {
//   //           needToken: true,
//   //           title: '子页21', //侧边栏标题
//   //           icon: 'el-icon-s-home', //图标
//   //         },
//   //       },
//   //       {
//   //         path: 'son2',
//   //         name: 'father2son2',
//   //         component: () => import('../views/home/father2/son2.vue'),
//   //         meta: {
//   //           needToken: true,
//   //           title: '子页22', //侧边栏标题
//   //           icon: 'el-icon-s-home', //图标
//   //         },
//   //       }]

//   //     }, {
//   //       path: 'father3',
//   //       name: 'father3',
//   //       component: () => import('../views/home/father3/father3.vue'),
//   //       redirect: 'father3/son1',
//   //       meta: {
//   //         needToken: true,
//   //         title: '一半权限模块', //侧边栏标题
//   //         icon: 'el-icon-s-home', //图标
//   //       },
//   //       children: [{
//   //         path: 'son1',
//   //         name: 'father3son1',
//   //         component: () => import('../views/home/father3/son1.vue'),
//   //         meta: {
//   //           needToken: true,
//   //           title: '子页31', //侧边栏标题
//   //           icon: 'el-icon-s-home', //图标
//   //         },
//   //       },
//   //       {
//   //         path: 'son2',
//   //         name: 'father3son2',
//   //         component: () => import('../views/home/father3/son2.vue'),
//   //         meta: {
//   //           needToken: true,
//   //           title: '子页32', //侧边栏标题
//   //           icon: 'el-icon-s-home', //图标
//   //         },
//   //       }]

//   //     }
//   //   ]
//   // },

//   {
//     path: '/404',
//     name: '404',
//     meta: { needToken: false },
//     component: () => import('../views/404/404.vue')
//   },
//   {
//     path: '*',//匹配未定义的路由
//     redirect: '/404'//重定向
//   }
// ]




const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('进入路由前置守卫', 'to', to)

  //判断本地是否有vuex的缓存,如果有则说明刷新过
  //需要立马恢复vuex信息,并且构造路由
  if (sessionStorage.getItem("storeBySessionStorage")) {
    let url = ''
    //判断路由是否重定向了,如果重定向了,需要在构造路由之后跳转到重定向之前的路由
    if (to.redirectedFrom) {
      // console.log('需要重定向,重定向路由为:', to.redirectedFrom)
      url = to.redirectedFrom
    }
    store.commit('reSendStateByShuaxin', url);
  }


  // console.log('进行路由元信息判断,随后进行跳转')
  if (to.meta.needToken) {
    if (localStorage.getItem('HStoken')) {
      next()
    } else {
      Message.warning('登录过期')
      next({
        path: '/login'
      })
    }
  } else {
    next()
  }
})

export default router
