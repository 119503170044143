<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {

  },
  created() {
    //当页面刷新的时候将vuex 信息保存到本地
    //随后会触发路由守卫事件,
    //在路由守卫事件中会将本地消息赋值给vuex且删除本地信息
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("storeBySessionStorage", JSON.stringify(this.$store.state));
      console.log('刷新了')
    });


  }

}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
</style>
