<template>
  <div class="BasicTable">
    <div class="tableBox">
      <el-table
        style="width: 100%; margin-top: 10px"
        v-loading="loading"
        ref="multipleTable"
        :data="listData"
        @selection-change="handleSelectionChange"
        :header-cell-style="{
          background: '#f6f8fa',
          color: 'rgb(51, 51, 51)',
          fontSize: '12px',
          fontWeight: 600,
        }"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in listOver"
          minWidth="180"
          :width="item.width || ''"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          v-if="item.show"
          :fixed="item.fixed || null"
        >
          <template slot-scope="scope">
            <div v-if="item.type == 'diyProp'">
              <slot
                name="diyProp"
                :data="scope.row[item.prop]"
                :prop="item.prop"
                :row="scope.row"
              ></slot>
            </div>
            <div v-else-if="item.type == 'diyLabel'">
              <slot
                name="diyLabel"
                :data="scope.row"
                :label="item.label"
                :row="scope.row"
              ></slot>
            </div>
            <div v-else>
              <el-tooltip
                popper-class="tps"
                :content="scope.row[item.prop] + ''"
                placement="top-start"
                :open-delay="1000"
              >
                <div class="overHide">{{ scope.row[item.prop] }}</div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-popover
        placement="bottom"
        width="240"
        v-model="visible"
        @hide="hidePopover"
      >
        <div class="chooseList" style="hight: 200px">
          <ul style="overflow: auto; max-height: 600px" class="list">
            <li
              @dragenter="dragenter($event, index)"
              @dragover="dragover($event, index)"
              @dragstart="dragstart(index)"
              draggable
              v-for="(item, index) in listOver"
              :key="index"
              style="margin: 10px 0"
            >
              <el-switch
                v-model="item.show"
                :width="30"
                :disabled="item.diy || false"
              >
              </el-switch
              ><span style="padding: 0 0 0 10px">{{ item.label }}</span>
            </li>
          </ul>
        </div>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="change(false)"
            >取消</el-button
          >
          <el-button type="primary" size="mini" @click="change(true)"
            >确定</el-button
          >
        </div>
        <div class="Customization" slot="reference"></div>
      </el-popover>
    </div>
    <div v-if="showPage" class="p-contianer" style="margin-top: 10px">
      <el-pagination
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :pager-count="5"
        :total="total"
        class="p-bar"
        background
        layout="prev, pager, next, sizes, total, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
  
<script>
export default {
  //loading 表格加载
  //listData 数据
  //tableMap 表格结构
  //total 数据总数
  //showPage 是否展示分页
  //showCustomization  是否支持自定义修改表格
  props: {
    loading: {
      type: Boolean,
    },
    listData: {
      type: Array,
    },
    tableMap: {
      type: Array,
    },
    total: {
      type: Number,
    },
    showPage: {
      type: Boolean,
      default: true,
    },
    showCustomization: {
      type: Boolean,
      require: true,
      default: true,
    },
    currentPage: {
      type: Number,
      require: true,
      default: 1,
    },
    pageSize: {
      type: Number,
      require: true,
      default: 10,
    },
  },
  data() {
    return {
      // tableMap: [],//基础数据
      listBegin: [], //修改前表单数据
      listOver: [], //修改后的数据
      pageSizes: [10, 20, 30],
      dragIndex: "",
      enterIndex: "",
      multipleSelection: [],
      visible: false, //保留该字段
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit("handleSelectionChange", this.multipleSelection);
    },
    handleSizeChange(value) {
      this.$emit("handleSizeChange", value);
    },
    handleCurrentChange(value) {
      this.$emit("handleCurrentChange", value);
    },
    dragstart(index) {
      this.dragIndex = index;
    },
    dragenter(e, index) {
      e.preventDefault();
      // 避免源对象触发自身的dragenter事件
      if (this.dragIndex !== index) {
        // this.loading = true
        const source = this.listOver[this.dragIndex];
        this.listOver.splice(this.dragIndex, 1);
        this.listOver.splice(index, 0, source);
        // 排序变化后目标对象的索引变成源对象的索引
        this.dragIndex = index;
        this.loading = false;
      }
    },
    dragover(e, index) {
      e.preventDefault();
    },
    //隐藏操作框
    hidePopover() {
      this.listOver = JSON.parse(JSON.stringify(this.listBegin));
    },
    //修改了表单顺序以及是否显示隐藏
    change(br) {
      this.visible = false;
      if (br) {
        //保存现修改数据,存到本地,且存储新的修改前数据
        this.listBegin = JSON.parse(JSON.stringify(this.listOver));
        localStorage.setItem(
          this.$route.path + "/tableSetting",
          JSON.stringify(this.listBegin)
        );
      } else {
        //放弃修改数据,应用为修改前的数据
        this.listOver = JSON.parse(JSON.stringify(this.listBegin));
      }
    },
    ifChangeBasicTableMap() {
      //判断本地是否有本地配置(/basicTableSetting 基础列表 非自定义列表)(/tableSetting 自定义列表)
      if (localStorage.getItem(this.$route.path + "/basicTableSetting")) {
        //有 和新的基本列表配置比较看是否有更改
        if (
          localStorage.getItem(this.$route.path + "/basicTableSetting") ==
          JSON.stringify(this.tableMap)
        ) {
          //没更改则获取自定以的数据
          this.listOver = JSON.parse(
            localStorage.getItem(this.$route.path + "/tableSetting")
          );
          this.listBegin = JSON.parse(JSON.stringify(this.listOver));
        } else {
          // 有更改,所有配置恢复到新的默认配置
          localStorage.setItem(
            this.$route.path + "/tableSetting",
            JSON.stringify(this.tableMap)
          );
          localStorage.setItem(
            this.$route.path + "/basicTableSetting",
            JSON.stringify(this.tableMap)
          );
          this.listOver = JSON.parse(JSON.stringify(this.tableMap));
          this.listBegin = JSON.parse(JSON.stringify(this.listOver));
        }
      } else {
        // 没有配置则配置到本地存储
        // 配置基本列表
        localStorage.setItem(
          this.$route.path + "/basicTableSetting",
          JSON.stringify(this.tableMap)
        );
        // 配置自定义基本列表
        localStorage.setItem(
          this.$route.path + "/tableSetting",
          JSON.stringify(this.tableMap)
        );
        this.listOver = JSON.parse(JSON.stringify(this.tableMap));
        this.listBegin = JSON.parse(JSON.stringify(this.listOver));
      }
    },
  },
  created() {
    if (this.showCustomization) {
      this.ifChangeBasicTableMap();
    } else {
      this.listOver = this.tableMap;
    }
  },
};
</script>

<style lang="less">
.BasicTable {
  .tableBox {
    position: relative;

    .chooseList {
      height: 200px;
      background-color: #f6f8fa;

      ul.list {
        padding: 0;
      }
    }

    .Customization {
      position: absolute;
      width: 10px;
      height: 50px;
      background-color: #c7d1da;
      top: 0;
      right: -10px;
      z-index: 99;
    }

    .overHide {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

// 超出提示文字样式
.el-dialog__body {
  padding: 0 20px;
}

.tps.el-tooltip__popper {
  max-width: 300px;
}

.tps.el-tooltip__popper.is-dark {
  background: #777;
}

.tps.el-tooltip__popper.is-light {
  background: red;
}
</style>
