import Vue from 'vue'
import Vuex from 'vuex'
import { getDiyRouterByAxios, mergeRouter } from '../router/DIYRouter'
import router from '@/router'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    getRoute: false,
    keyByrouter: '',
  },
  getters: {
  },
  mutations: {
    login(state, HStoken) {
      state.isLogin = true
      localStorage.setItem('HStoken', HStoken)
    },
    layout(state) {
      state.isLogin = false
      state.getRoute = false
      // 需要一个方法重置路由
      //do()

      localStorage.removeItem('HStoken')

    },
    changeRoute(state, val) {
      if (state.isLogin && !state.getRoute) {
        // console.log('点击登陆后,构造路由')
        state.keyByrouter = val
        let chooseDiyRouter = getDiyRouterByAxios(state.keyByrouter)
        mergeRouter(chooseDiyRouter)
        // console.log('构造后的路由:', router.options.routes)
        state.getRoute = true
      }
    },
    //刷新页面后会触发该方法
    reSendStateByShuaxin(state, url) {
      // console.log('刷新后,将本地信息重新赋值给vuex,随后删除')
      let obj = JSON.parse(sessionStorage.getItem("storeBySessionStorage"))
      Object.keys(obj).forEach(item => {
        state[item] = obj[item]
      })
      sessionStorage.removeItem("storeBySessionStorage")


      if (obj.isLogin && obj.getRoute) {
        //恢复vuex信息后,有且只有刷新前登录了,且获取过一次路由才会在刷新后重新调用方法构造一次路由
        let chooseDiyRouter = getDiyRouterByAxios(state.keyByrouter)
        mergeRouter(chooseDiyRouter)
        // console.log('刷新之后调用了方法,重新构造了路由,为:', router.options.routes)
        if (url !== '') {
          router.push({
            path: url
          })
        }
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
