import router from './index'
const basicRouter = [
    // meta: {
    //     title: '标题', //侧边栏标题
    //     needToken: true, //是否需要登录
    //     icon: '图标', //图标 只有一级标题需要
    //     name: '',//用于权限判断
    //     system:true, //是否是系统用于选择系统页面判断 只有一级标题需要
    //     systemType:1, //系统类型 1基础模块 2开拓模块
    //     remark:'',//只有一级标题需要 系统备注
    //     showAside:br ,//是否显示在侧边菜单栏  最对非一级标题有效 
    //     showRouterTree:br ,//是否显示在路由权限路由树里 基本路由需要为false
    //   },

    // 一级模块 xxx系统


    // {
    //     path: '/aaa',
    //     name: 'aaa',
    //     redirect: '/aaa/xxx',
    //     meta: {
    //         title: '',//侧边栏标题
    //         needToken: true,//是否需要登录
    //         icon: 'el-icon-s-home',//图标 只有一级标题需要
    //         system: true,//是否是系统用于选择系统页面判断 只有一级标题需要
    //         systemType:1, //系统类型 1基础模块 2开拓模块
    //         remark:'',//只有一级标题需要 系统备注
    //         showAside:br ,//是否显示在侧边菜单栏  最对非一级标题有效 
    //         showRouterTree:br ,//是否显示在路由权限路由树里 基本路由需要为false
    //     },
    //     component: () => import('../views/nurseSystem/home.vue'),
    //     children:[]
    // }







    {
        path: '/',
        redirect: '/login',
        meta: {
            title: '重定向',
            needToken: false,
            icon: '',
            system: false,
            systemType: null,
            remark: null,
            showAside: false,
            showRouterTree: false,
        }
    },
    {
        path: '/login',
        name: 'login',
        meta: { needToken: false },
        component: () => import('../views/login/login1.vue'),
        meta: {
            title: '登录页',
            needToken: false,
            icon: '',
            system: false,
            systemType: null,
            remark: null,
            showAside: false,
            showRouterTree: false,
        }
    },
    {
        path: '/choose',
        name: 'choose',
        meta: { needToken: true },
        component: () => import('../views/choosepage.vue'),
        meta: {
            title: '模块选择页',
            needToken: true,
            icon: '',
            system: false,
            systemType: null,
            remark: null,
            showAside: false,
            showRouterTree: false,
        }
    },
    {
        path: '/ab',
        name: 'ab',
        meta: { needToken: false },
        component: () => import(/* webpackChunkName: "about" */ '../views/ab.vue'),
        meta: {
            title: '广告页',
            needToken: false,
            icon: '',
            system: false,
            systemType: null,
            remark: null,
            showAside: false,
            showRouterTree: false,
        }
    }, {
        path: '/404',
        name: '404',
        meta: { needToken: false },
        component: () => import('../views/404/404.vue'),
        meta: {
            title: '404',
            needToken: false,
            icon: '',
            system: false,
            systemType: null,
            remark: null,
            showAside: false,
            showRouterTree: false,
        }
    },
    {
        path: '*',//匹配未定义的路由
        redirect: '/404',//重定向
        meta: {
            title: '重定向404',
            needToken: false,
            icon: '',
            system: false,
            systemType: null,
            remark: null,
            showAside: false,
            showRouterTree: false,
        }
    }
]
const DiyRouter = [
    {
        // 优护后台系统
        path: '/nurseStyle',
        name: 'nurseStyle',
        redirect: '/nurseStyle/basicModule',
        meta: {
            title: '优护后台系统',
            needToken: true,
            icon: 'el-icon-s-home',
            system: true,
            systemType: 1,
            remark: '后台管理系统',
            showAside: true,
            showRouterTree: true,
        },
        component: () => import('../views/nurseSystem/home.vue'),
        children: [
            {
                path: 'homeMain',
                name: 'homeMain-nurseSystem',
                component: () => import('../views/nurseSystem/homeMain.vue'),
                meta: {
                    title: '主页',
                    needToken: true,
                    icon: 'el-icon-s-home',
                    system: true,
                    systemType: 1,
                    remark: '后台管理系统',
                    showAside: false,
                    showRouterTree: true,
                },
            },
            {
                path: 'basicModule',
                name: 'basicModule',
                redirect: 'basicModule/serveType',
                component: () => import('../views/nurseSystem/basicModule/index.vue'),
                meta: {
                    title: '基础数据',
                    needToken: true,
                    icon: 'el-icon-s-home',
                    showAside: true,
                    showRouterTree: true,
                },
                children: [
                    {
                        path: 'serveType',
                        name: 'serveType',
                        component: () => import('../views/nurseSystem/basicModule/serveType.vue'),
                        meta: {
                            title: '服务类型',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'serveType_add',
                        name: 'serveType_add',
                        component: () => import('../views/nurseSystem/basicModule/serveType_add.vue'),
                        meta: {
                            title: '服务类型新增',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: false,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'serveType_edit',
                        name: 'serveType_edit',
                        component: () => import('../views/nurseSystem/basicModule/serveType_edit.vue'),
                        meta: {
                            title: '服务类型编辑',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: false,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'material',
                        name: 'material',
                        component: () => import('../views/nurseSystem/basicModule/material.vue'),
                        meta: {
                            title: '材料管理',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'serveModule',
                        name: 'serveModule',
                        component: () => import('../views/nurseSystem/basicModule/serveModule.vue'),
                        meta: {
                            title: '服务项目',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'serveModule_add',
                        name: 'serveModule_add',
                        component: () => import('../views/nurseSystem/basicModule/serveModule_add.vue'),
                        meta: {
                            title: '服务项目新增',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: false,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'serveModule_edit',
                        name: 'serveModule_edit',
                        component: () => import('../views/nurseSystem/basicModule/serveModule_edit.vue'),
                        meta: {
                            title: '服务项目编辑',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: false,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'material_add',
                        name: 'material_add',
                        component: () => import('../views/nurseSystem/basicModule/material_add.vue'),
                        meta: {
                            title: '材料管理新增',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: false,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'material_edit',
                        name: 'material_edit',
                        component: () => import('../views/nurseSystem/basicModule/material_edit.vue'),
                        meta: {
                            title: '材料管理编辑',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: false,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'administrative',
                        name: 'administrative',
                        component: () => import('../views/nurseSystem/basicModule/administrative.vue'),
                        meta: {
                            title: '行政区域',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                ]
            },
            {
                path: 'institutionModule',
                name: 'institutionModule',
                redirect: 'institutionModule/institutionlist',
                component: () => import('../views/nurseSystem/institutionModule/index.vue'),
                meta: {
                    title: '机构管理',
                    needToken: true,
                    icon: 'el-icon-s-home',
                    showAside: true,
                    showRouterTree: true,
                },
                children: [
                    {
                        path: 'institutionlist',
                        name: 'institutionlist',
                        component: () => import('../views/nurseSystem/institutionModule/institutionlist.vue'),
                        meta: {
                            title: '机构列表',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'institutionlist_add',
                        name: 'institutionlist_add',
                        component: () => import('../views/nurseSystem/institutionModule/institutionlist_add.vue'),
                        meta: {
                            title: '机构列表新增',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: false,
                            showRouterTree: true,

                        },
                    },
                    {
                        path: 'institutionlist_edit',
                        name: 'institutionlist_edit',
                        component: () => import('../views/nurseSystem/institutionModule/institutionlist_edit.vue'),
                        meta: {
                            title: '机构列表修改',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: false,
                            showRouterTree: true,
                        },
                    }
                ]

            },
            {
                path: 'userModule',
                name: 'userModule',
                redirect: 'userModule/patient',
                component: () => import('../views/nurseSystem/userModule/index.vue'),
                meta: {
                    title: '用户管理',
                    needToken: true,
                    icon: 'el-icon-s-home',
                    showAside: true,
                    showRouterTree: true,
                },
                children: [
                    {
                        path: 'patient',
                        name: 'patient',
                        component: () => import('../views/nurseSystem/userModule/patient.vue'),
                        meta: {
                            title: '患者管理',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'address',
                        name: 'address',
                        component: () => import('../views/nurseSystem/userModule/address.vue'),
                        meta: {
                            title: '用户地址',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'archives',
                        name: 'archives',
                        component: () => import('../views/nurseSystem/userModule/archives.vue'),
                        meta: {
                            title: '健康档案',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'archives_see',
                        name: 'archives_see',
                        component: () => import('../views/nurseSystem/userModule/archives_see.vue'),
                        meta: {
                            title: '健康档案详情',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: false,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'nurse',
                        name: 'nurse',
                        component: () => import('../views/nurseSystem/userModule/nurse.vue'),
                        meta: {
                            title: '护士管理',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'authentication',
                        name: 'authentication',
                        component: () => import('../views/nurseSystem/userModule/authentication.vue'),
                        meta: {
                            title: '个人认证',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'aptitude',
                        name: 'aptitude',
                        component: () => import('../views/nurseSystem/userModule/aptitude.vue'),
                        meta: {
                            title: '资质审批',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    }
                ]
            },
            {
                path: 'orderModule',
                name: 'orderModule',
                redirect: 'orderModule/booking',
                component: () => import('../views/nurseSystem/orderModule/index.vue'),
                meta: {
                    title: '订单管理',
                    needToken: true,
                    icon: 'el-icon-s-home',
                    showAside: true,
                    showRouterTree: true,
                },
                children: [
                    {
                        path: 'booking',
                        name: 'booking',
                        component: () => import('../views/nurseSystem/orderModule/booking.vue'),
                        meta: {
                            title: '预约订单',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'onsite',
                        name: 'onsite',
                        component: () => import('../views/nurseSystem/orderModule/onsite.vue'),
                        meta: {
                            title: '上门服务单',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'Satisfaction',
                        name: 'Satisfaction',
                        component: () => import('../views/nurseSystem/orderModule/Satisfaction.vue'),
                        meta: {
                            title: '满意度评价',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                ]
            },
            {
                path: 'financeModule',
                name: 'financeModule',
                redirect: 'financeModule/purse',
                component: () => import('../views/nurseSystem/financeModule/index.vue'),
                meta: {
                    title: '财务管理',
                    needToken: true,
                    icon: 'el-icon-s-home',
                    showAside: true,
                    showRouterTree: true,
                },
                children: [
                    {
                        path: 'purse',
                        name: 'purse',
                        component: () => import('../views/nurseSystem/financeModule/purse.vue'),
                        meta: {
                            title: '钱包管理',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'payouts',
                        name: 'payouts',
                        component: () => import('../views/nurseSystem/financeModule/payouts.vue'),
                        meta: {
                            title: '现金提取审批',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'tradeWx',
                        name: 'tradeWx',
                        component: () => import('../views/nurseSystem/financeModule/trade_wx.vue'),
                        meta: {
                            title: '微信交易流水',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    }, {
                        path: 'trade',
                        name: 'trade',
                        component: () => import('../views/nurseSystem/financeModule/trade.vue'),
                        meta: {
                            title: '交易流水',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                ]
            },
            {
                path: 'permissionsModule',
                name: 'permissions',
                redirect: 'permissions/route',
                component: () => import('../views/nurseSystem/permissionsModule/index.vue'),
                meta: {
                    title: '权限管理',
                    needToken: true,
                    icon: 'el-icon-s-home',
                    showAside: true,
                    showRouterTree: true,
                },
                children: [
                    {
                        path: 'route',
                        name: 'route',
                        component: () => import('../views/nurseSystem/permissionsModule/route.vue'),
                        meta: {
                            title: '路由列表',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'route_add',
                        name: 'route_add',
                        component: () => import('../views/nurseSystem/permissionsModule/route_add.vue'),
                        meta: {
                            title: '路由列表新增',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: false,
                            showRouterTree: true,
                        },
                    },
                    {
                        path: 'role',
                        name: 'role',
                        component: () => import('../views/nurseSystem/permissionsModule/role.vue'),
                        meta: {
                            title: '角色列表',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: true,
                            showRouterTree: true,
                        },
                    }, {
                        path: 'role_add',
                        name: 'role_add',
                        component: () => import('../views/nurseSystem/permissionsModule/role_add.vue'),
                        meta: {
                            title: '角色列表_新增',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: false,
                            showRouterTree: true,
                        },
                    }, {
                        path: 'role_edit',
                        name: 'role_edit',
                        component: () => import('../views/nurseSystem/permissionsModule/role_edit.vue'),
                        meta: {
                            title: '角色列表+修改',
                            needToken: true,
                            icon: 'el-icon-s-home',
                            showAside: false,
                            showRouterTree: true,
                        },
                    }
                ]
            }


        ]
    },
    {
        path: '/home',
        name: 'home',
        redirect: '/home/homeMain',
        meta: {
            title: '测试系统',//侧边栏标题
            needToken: true,//是否需要登录
            icon: 'el-icon-s-home',//图标 只有一级标题需要
            system: false,//是否是系统用于选择系统页面判断 只有一级标题需要
            systemType: 1, //系统类型 1基础模块 2开拓模块
            remark: '',//只有一级标题需要 系统备注
            showAside: false,//是否显示在侧边菜单栏  最对非一级标题有效 
            showRouterTree: false,//是否显示在路由权限路由树里 基本路由需要为false
        },
        component: () => import('../views/home/home.vue'),
        children: [
            {
                path: 'homeMain',
                name: 'homeMain',
                component: () => import('../views/home/homeMain.vue'),
                meta: {
                    needToken: true,
                    title: '主页', //侧边栏标题
                    icon: 'el-icon-s-home', //图标
                },
            },
            {
                path: 'home1',
                name: 'home1',
                component: () => import('../views/home/home1.vue'),
                meta: {
                    needToken: true,
                    title: '单页面1', //侧边栏标题
                    icon: 'el-icon-s-home', //图标
                },
            }, {
                path: 'home2',
                name: 'home2',
                component: () => import('../views/home/home2.vue'),
                meta: {
                    needToken: true,
                    title: '单页面2', //侧边栏标题
                    icon: 'el-icon-s-home', //图标
                },
            }, {
                path: 'father1',
                name: 'father1',
                component: () => import('../views/home/father1/father1.vue'),
                redirect: 'father1/son1',
                meta: {
                    needToken: true,
                    title: '无权限模块', //侧边栏标题
                    icon: 'el-icon-s-home', //图标
                },
                children: [{
                    path: 'son1',
                    name: 'father1son1',
                    component: () => import('../views/home/father1/son1.vue'),
                    meta: {
                        needToken: true,
                        title: '子页11', //侧边栏标题
                        icon: 'el-icon-s-home', //图标
                    },
                },
                {
                    path: 'son2',
                    name: 'father1son2',
                    component: () => import('../views/home/father1/son2.vue'),
                    meta: {
                        needToken: true,
                        title: '子页12', //侧边栏标题
                        icon: 'el-icon-s-home', //图标
                    },
                }]

            }, {
                path: 'father2',
                name: 'father2',
                component: () => import('../views/home/father2/father2.vue'),
                redirect: 'father2/son1',
                meta: {
                    needToken: true,
                    title: '有权限模块', //侧边栏标题
                    icon: 'el-icon-s-home', //图标
                },
                children: [{
                    path: 'son1',
                    name: 'father2son1',
                    component: () => import('../views/home/father2/son1.vue'),
                    meta: {
                        needToken: true,
                        title: '子页21', //侧边栏标题
                        icon: 'el-icon-s-home', //图标
                    },
                },
                {
                    path: 'son2',
                    name: 'father2son2',
                    component: () => import('../views/home/father2/son2.vue'),
                    meta: {
                        needToken: true,
                        title: '子页22', //侧边栏标题
                        icon: 'el-icon-s-home', //图标
                    },
                }]

            }, {
                path: 'father3',
                name: 'father3',
                component: () => import('../views/home/father3/father3.vue'),
                redirect: 'father3/son1',
                meta: {
                    needToken: true,
                    title: '一半权限模块', //侧边栏标题
                    icon: 'el-icon-s-home', //图标
                },
                children: [{
                    path: 'son1',
                    name: 'father3son1',
                    component: () => import('../views/home/father3/son1.vue'),
                    meta: {
                        needToken: true,
                        title: '子页31', //侧边栏标题
                        icon: 'el-icon-s-home', //图标
                    },
                },
                {
                    path: 'son2',
                    name: 'father3son2',
                    component: () => import('../views/home/father3/son2.vue'),
                    meta: {
                        needToken: true,
                        title: '子页32', //侧边栏标题
                        icon: 'el-icon-s-home', //图标
                    },
                }]

            }
        ]
    },


]

export { basicRouter }


//调用接口获取权限信息,对权限路由进行筛选,筛选出符合的路由并返回
export function getDiyRouterByAxios(val) {
    let chooseDiyRouter = []
    DiyRouter.forEach(item => {
        if (val) {

        }
        chooseDiyRouter.push(item)
    })
    // console.log('根据接口返回的路由获取到需要剥离后的动态路由')
    return chooseDiyRouter
}

//传入权限路由与基础路由进行合并 并渲染到路由树上
export function mergeRouter(chooseDiyRouter) {
    // console.log('融合路由')
    let RouterMerge = chooseDiyRouter.concat(basicRouter)
    router.options.routes = RouterMerge
    chooseDiyRouter.forEach(item => {
        router.addRoute(item)
    })
}