<template>
  <div>
    <div class="main">
      <header class="main-basic">{{ title }}</header>
      <div class="toright">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style lang='less' scoped>
.main {
  width: 100%;
}

.toright {
  float: right;
}

.main-basic {
  display: inline-block;
  font-family: Source Han Sans CN;
  font-weight: 700;
  color: #333333;
  font-size: 16px;
  margin-bottom: 20px;
  padding-left: 10px;
  border-left: 6px solid #1484ff;
}
</style>