import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import axios from 'axios'
Vue.prototype.$axios = axios

//图片裁剪
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)

//rsa加密
import Rsa from "@/store/rsa/index"
Vue.prototype.Rsa = Rsa

//全局组件
import labelTitle from './components/title/index.vue' //标题
Vue.component('labelTitle', labelTitle)

import BasicTable from '@/components/table/basicTable'  //表单
Vue.component('BasicTable', BasicTable)

import BasicTablePic from '@/components/table/table_pic'  //表单
Vue.component('BasicTablePic', BasicTablePic)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
